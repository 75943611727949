@import url(https://fonts.googleapis.com/css2?family=Salsa:wght@300;400;600&family=Ubuntu:wght@300;400;500;700&family=Inter:wght@100..900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Danse Macabre font */
@font-face {
  font-family: 'Danse Macabre';
  src: local('CaslonAntique'), url(/static/media/CaslonAntique.1969c960.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Danse Macabre';
  src: local('CaslonAntique-Bold'), url(/static/media/CaslonAntique-Bold.15800ff3.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Danse Macabre';
  src: local('CaslonAntique-Italic'), url(/static/media/CaslonAntique-Italic.d0131e67.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Danse Macabre';
  src: local('CaslonAntique-BoldItalic'), url(/static/media/CaslonAntique-BoldItalic.0839b759.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}



body {
  color: white;
  background: black;
  /* background: #ff6d00; */
  /* background: #d02800; */
  /* background-image: linear-gradient(180deg, #ba0c2f 0, #ba0c2f 8%, #FFF 8%, #FFF 15%, #ba0c2f 15%, #ba0c2f 23%, #FFF 23%, #FFF 31%, #ba0c2f 31%, #ba0c2f 38%, #FFF 38%, #FFF 46%, #ba0c2f 46%, #ba0c2f 54%, #FFF 54%, #FFF 62%, #ba0c2f 62%, #ba0c2f 69%, #FFF 69%, #FFF 77%, #ba0c2f 77%, #ba0c2f 85%, #FFF 85%, #FFF 92%, #ba0c2f 92%, #ba0c2f 100%); */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}
