@import url('https://fonts.googleapis.com/css2?family=Salsa:wght@300;400;600&family=Ubuntu:wght@300;400;500;700&family=Inter:wght@100..900&display=swap');

html {
  height: 100%;
  margin: 0;
  padding: 0;
}



body {
  color: white;
  background: black;
  /* background: #ff6d00; */
  /* background: #d02800; */
  /* background-image: linear-gradient(180deg, #ba0c2f 0, #ba0c2f 8%, #FFF 8%, #FFF 15%, #ba0c2f 15%, #ba0c2f 23%, #FFF 23%, #FFF 31%, #ba0c2f 31%, #ba0c2f 38%, #FFF 38%, #FFF 46%, #ba0c2f 46%, #ba0c2f 54%, #FFF 54%, #FFF 62%, #ba0c2f 62%, #ba0c2f 69%, #FFF 69%, #FFF 77%, #ba0c2f 77%, #ba0c2f 85%, #FFF 85%, #FFF 92%, #ba0c2f 92%, #ba0c2f 100%); */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}